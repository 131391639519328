import { Modal, type ModalProps } from 'antd';
import styled from 'styled-components';

export const ModalConfirm = ({
  icon,
  content,
  title,
  ...props
}: Omit<ModalProps, 'title'> & {
  title?: string;
  icon?: React.ReactNode;
  content?: React.ReactNode;
}) => {
  const titleWithIcon = (
    <StyledHeader>
      <StyledIcon>{icon}</StyledIcon>
      {title}
    </StyledHeader>
  );
  return (
    <Modal
      bodyStyle={{ padding: '0 24px', ...props.bodyStyle }}
      title={titleWithIcon}
      {...props}
    ></Modal>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIcon = styled.div`
  color: ${({ theme }) => theme.colors.yellow};
`;
