import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const PRIMARY_RECORD_QUERY = graphql(`
  query PrimaryRecordGenrality($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      name
      status
      stats {
        requestRecords {
          totalCount
          compliantCount
        }
        documents {
          totalCount
        }
      }
    }
  }
`);

export const usePrimaryRecordGenerality = ({
  recordId,
}: {
  recordId: string;
}) => {
  const { data, loading } = useQuery(PRIMARY_RECORD_QUERY, {
    variables: {
      primaryRecordInput: { id: recordId },
    },
  });

  return {
    primaryRecord: data?.primaryRecord,
    loading,
  };
};
